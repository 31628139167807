import React, { useState } from 'react';
import { FaExpandAlt } from 'react-icons/fa'; // Icon for more AI-style feel

// Random Real Images - Replace these with actual transformed images
const images = [
  './images/comp-img/1.jpg',
  './images/comp-img/20.jpg',
  './images/comp-img/4.jpg',
  './images/comp-img/10.jpg',
  './images/comp-img/16.jpg',
  './images/comp-img/18.jpg',
  './images/comp-img/19.jpg',
  './images/comp-img/11.jpg',
  './images/comp-img/15.jpg',
];

const PortfolioSection = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  // Open Modal with Selected Image
  const openModal = (image) => {
    setSelectedImage(image);
  };

  // Close Modal
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section className="pt-16 pb-32 bg-gradient-to-r from-gray-100 to-white">
      <div className="max-w-7xl mx-auto px-6">
        {/* Heading */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">
            AI-Enhanced Outfit Transformations
          </h2>
          <p className="text-gray-600 mt-4 text-lg">
            See how our AI transforms outfits with just a click.
          </p>
        </div>

        {/* Portfolio Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {images.map((image, index) => (
            <div
              key={index}
              className="group relative cursor-pointer transform transition-transform hover:scale-105 hover:-rotate-1"
              onClick={() => openModal(image)}
            >
              {/* Image Card */}
              <div className="relative shadow-lg rounded-lg overflow-hidden bg-gradient-to-br from-purple-100 to-indigo-100 p-4">
                <img
                  src={image}
                  alt={`Outfit Change ${index + 1}`}
                  className="w-full object-cover rounded-lg group-hover:shadow-xl transition-shadow duration-300"
                />
                {/* AI Icon Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                  <FaExpandAlt className="text-white text-4xl" />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Modal */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4"
            onClick={closeModal}
          >
            
            <div className="max-h-screen max-w-screen-lg relative">
                <button
                  className="absolute hidden md:block -top-10 -right-10 bg-gray-900 hover:bg-gray-300 text-gray-50 p-2 h-10 w-10 rounded-full focus:outline-none"
                  onClick={closeModal}
                >
                  &#10005;
                </button>
              <div
                className="relative bg-white rounded-lg shadow-2xl animate-fadeInUp overflow-hidden"
                onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
              >
                {/* Modal Image */}
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="w-full h-auto py-4 max-h-[80vh] object-contain rounded-lg"
                />
                {/* Close Button */}

              </div>
            </div>
          </div>
        )}

      </div>
    </section>
  );
};

export default PortfolioSection;
