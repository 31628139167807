import React from 'react';
import AboutUsSection from './About/AboutUsSection';
import CallToActionSection from './Home/Calltoactionsection';
import TestimonialsSection from './Home/Testimonialsection';
import SkillsSection from './About/SkillsSection';

const About = () => {
  return (
    <> 
      <AboutUsSection />
      <SkillsSection />
      <TestimonialsSection /> 
      <CallToActionSection />
    </>
  );
};

export default About;
