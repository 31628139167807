import React from 'react';
import { FaStar } from 'react-icons/fa'; // For the star rating icons

const AboutUsSection = () => {
  return (
    <section className="bg-white py-16 md:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center">
        {/* Left Side - Image */}
        <div className="md:w-1/2 mb-8 md:mb-0 relative">
          {/* UI Mockup or Platform Image */}
          <img 
            src="./images/about-1.webp" 
            alt="ClothChanger Demo"
            className="w-full h-auto rounded-lg shadow-xl" 
          />
          {/* Optional overlay or shapes for modern design */}
        </div>

        {/* Right Side - Content */}
        <div className="md:w-1/2 md:pl-12 text-center md:text-left">
          {/* Label */}
          <div className="inline-block bg-purple-100 text-purple-700 font-semibold py-1 px-3 rounded-full text-sm mb-4">
            About Us
          </div>
          {/* Headline */}
          <h2 className="text-4xl font-bold text-gray-900 mb-6 leading-tight">
            Transform Your Style with AI-Powered Clothing Changes
          </h2>
          {/* Subheadline */}
          <p className="text-lg text-gray-600 mb-6">
            At ClothChanger.ai, we leverage cutting-edge AI technology to help you effortlessly change your clothing in photos. Whether you want to explore new outfits, switch clothing colors, or experiment with fashion designs, our platform is your go-to solution for instant style transformations.
          </p>
          {/* Ratings and Social Proof */}
          <div className="flex items-center justify-center md:justify-start space-x-2 mb-6">
            <div className="flex items-center text-yellow-500">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
            <span className="text-gray-700 font-semibold">Based on 5,000+ reviews</span>
          </div>

          {/* Logos (you can replace with partner or client logos) */}
          <div className="md:flex items-center mx-auto justify-center md:justify-start space-x-2">
            <img src="./images/brand/1.jpg" alt="Brand 1" className="h-12 items-center md:h-8 border mt-2 rounded-xl border-gray-100  md:max-w-36" />
            <img src="./images/brand/2.jpg" alt="Brand 2" className="h-12 items-center md:h-8 border mt-2 rounded-xl border-gray-100  md:max-w-36" />
            <img src="./images/brand/3.jpg" alt="Brand 3" className="h-12 items-center md:h-8 border mt-2 rounded-xl border-gray-100  md:max-w-36" />
            <img src="./images/brand/4.jpg" alt="Brand 4" className="h-12 items-center md:h-8 border mt-2 rounded-xl border-gray-100  md:max-w-36" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
