import React from 'react';

const skillsData = [
  { name: 'AI Styling Accuracy', percentage: 95 },
  { name: 'Clothing Pattern Recognition', percentage: 90 },
  { name: 'Color Matching Technology', percentage: 92 },
  { name: 'User Personalization', percentage: 88 },
//   { name: 'Outfit Recommendations', percentage: 93 },
];

const SkillsSection = () => {
  return (
    <section className="py-16 bg-gray-50 text-gray-100">
      <div className="max-w-7xl mx-auto px-6 md:px-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                      {/* Skills Description */}
          <div className="space-y-6">
            <h2 className="text-4xl font-bold text-gray-900 mb-6 leading-tight">
              Our AI Expertise
            </h2>
            <p className="text-lg text-gray-500">
              At ClothChanger.AI, our advanced algorithms are designed to provide the most accurate 
              outfit transformations in the industry. Whether it's recognizing the finest clothing details or 
              offering personalized outfit suggestions, we continually strive for excellence in every aspect.
            </p>
            <p className="text-lg text-gray-500">
              Each feature is finely tuned to deliver top-quality results, allowing users to explore new looks 
              and styles with complete accuracy and ease.
            </p>
          </div>
          {/* Skills Bars Section */}
          <div className="pt-12">
            {skillsData.map((skill, index) => (
              <div key={index} className="mb-8">
                <div className="flex justify-between mb-2">
                  <h4 className="text-lg font-medium text-gray-600">{skill.name}</h4>
                  <span className="text-lg font-bold text-violet-400">{skill.percentage}%</span>
                </div>
                <div className="relative w-full h-2 bg-gray-400 rounded-full overflow-hidden">
                  <div
                    className="absolute top-0 left-0 h-full bg-gradient-to-r from-purple-400 to-violet-500 rounded-full"
                    style={{ width: `${skill.percentage}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>


        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
