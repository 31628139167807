import React from 'react';
import {
  FaMagic,
  FaPalette,
  FaTshirt,
  FaBriefcase,
  FaGhost,
} from 'react-icons/fa';

const sections = [
  {
    title: 'Instantly Change Clothes with AI',
    description:
      "Harness the power of cutting-edge AI technology to change outfits in your photos effortlessly. With ClothChanger.ai, you can upload any picture, select your desired clothing style, and let AI do the work. Whether you're looking for a casual outfit or a formal suit, it's as easy as clicking a button. Say goodbye to tedious manual editing and embrace the future of fashion with AI!",
    image: './images/comp-img/7.jpg',
    icon: <FaTshirt className="text-violet-600 text-3xl mb-4" />,
  },
  // {
  //   title: 'Change Clothes Color in Photos Online Free',
  //   description:
  //     "Ever wondered how you’d look in different colors? Now you can! Upload your image, brush over the clothes you want to change, and choose the color you’ve always dreamed of trying. Whether it’s a bold red for a night out or calming blue for a professional look, AI-powered color change will get it done for you—no design skills needed!",
  //   image: './images/comp-img/14.jpg',
  //   icon: <FaPalette className="text-violet-600 text-3xl mb-4" />,
  // },
  // {
  //   title: 'AI Magically Changes Clothes Patterns',
  //   description:
  //     'Our AI pattern changer allows you to experiment with different designs and patterns on your clothes in seconds. Try out floral prints, abstract designs, or stripes without buying new clothes. Let AI breathe fresh life into your wardrobe by automatically replacing your current patterns with the trendiest ones.',
  //   image: './images/comp-img/17.jpg',
  //   icon: <FaMagic className="text-violet-600 text-3xl mb-4" />,
  // },
  {
    title: 'Upgrade to Professional Attire with AI',
    description:
      'Need a professional look for your next LinkedIn update or job application? With our AI outfit changer, you can turn casual outfits into business attire in seconds. Transform your selfies into corporate headshots by swapping t-shirts for tailored suits, giving you the polished, executive look you need for professional success.',
    image: './images/comp-img/5.jpg',
    icon: <FaBriefcase className="text-violet-600 text-3xl mb-4" />,
  },
  {
    title: 'Try on Halloween Costumes with AI',
    description:
      'Get into the Halloween spirit without the hassle of trying on physical costumes! With our AI costume changer, you can see yourself as a witch, vampire, superhero, or any iconic character within seconds. Whether it’s for a party or a photo, discover the most exciting looks this Halloween using AI.',
      image: './images/comp-img/17.jpg',
    icon: <FaGhost className="text-violet-600 text-3xl mb-4" />,
  },
];

const LayoutComponent = () => {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-6">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center justify-between gap-12 mb-24 ${
              index % 2 !== 0 ? 'md:flex-row-reverse' : ''
            }`}
          >
            {/* Image Section */}
            <div className="w-full md:w-1/2">
              <img
                src={section.image}
                alt={section.title}
                className="w-full h-auto object-cover rounded-lg shadow-lg"
              />
            </div>

            {/* Content Section */}
            <div className="w-full md:w-1/2 text-left">
              {/* Icon */}
              <div className="flex justify-start md:mb-4">
                {section.icon}
              </div>

              {/* Title */}
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                {section.title}
              </h2>

              {/* Description */}
              <p className="text-lg text-gray-700 leading-relaxed">
                {section.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LayoutComponent;
