import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const faqs = [
  {
    question: 'How does AI clothes changer work?',
    answer: 'Our AI clothes changer analyzes the image of the person, identifies the clothing area, and allows users to replace their outfits with new AI-generated outfits in a matter of seconds. It uses deep learning techniques to adjust the clothes naturally.',
  },
  {
    question: 'Can AI change clothes realistically?',
    answer: 'Yes, the AI is designed to create realistic clothing swaps by considering fabric, fit, and texture to ensure the new clothes appear as if they are truly worn by the subject in the image.',
  },
  {
    question: 'Is the AI suitable for all body types?',
    answer: 'Absolutely! Our AI is built to accommodate different body types and ensure that the clothing fits naturally and looks great on every user.',
  }, 
  {
    question: 'Can I upload my own outfit design to be applied?',
    answer: 'At the moment, we offer a wide selection of pre-designed outfits, but we are working on enabling users to upload their own custom designs soon!',
  },
  {
    question: 'Is there a limitation on the types of clothes AI can change?',
    answer: 'For the most part, the AI can handle a wide variety of clothing styles, from casual wear to formal outfits. However, extremely complex patterns or accessories may require additional fine-tuning in future updates.',
  },
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="py-16 bg-gradient-to-b from-gray-100 to-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold text-center text-gray-900 mb-12">
          Frequently Asked Questions
        </h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`transition-all duration-500 rounded-lg shadow-md hover:shadow-lg overflow-hidden ${
                activeIndex === index ? 'bg-violet-50' : 'bg-white'
              }`}
            >
              {/* Question */}
              <div
                className={`flex justify-between items-center cursor-pointer py-4 px-6 hover:bg-violet-100 transition-colors duration-300 ${
                  activeIndex === index ? 'text-violet-700' : 'text-gray-900'
                }`}
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-lg font-semibold">
                  {faq.question}
                </h3>
                <span
                  className={`transition-transform duration-300 ${
                    activeIndex === index ? 'rotate-180' : ''
                  }`}
                >
                  {activeIndex === index ? <FaMinus /> : <FaPlus />}
                </span>
              </div>
              {/* Answer */}
              <div
                className={`px-6 bg-violet-50 overflow-hidden transition-max-height duration-500 ease-in-out ${
                  activeIndex === index ? 'max-h-screen py-4' : 'max-h-0'
                }`}
              >
                <p className="text-gray-700">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
