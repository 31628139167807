import React from 'react';

const testimonials = [
  {
    name: "Elena Martinez",
    role: "Fashion Blogger at ChicStreet",
    image: "https://randomuser.me/api/portraits/women/12.jpg",
    text: "ClothChanger.AI has transformed the way I plan my outfits. I can instantly try on different styles and colors, making my fashion blogging process so much smoother. Love it!",
  },
  {
    name: "Sophia Lee",
    role: "Freelance Photographer",
    image: "https://randomuser.me/api/portraits/women/14.jpg",
    text: "As a photographer, the ability to change my clients' outfits without reshooting has been a game-changer. ClothChanger.AI saves me so much time in post-production!",
  },
  {
    name: "Daniel Williams",
    role: "Graphic Designer at PixelLab",
    image: "https://randomuser.me/api/portraits/men/18.jpg",
    text: "The AI-powered clothing swap feature is incredible. I can experiment with different styles and colors, which helps my design workflow when creating mockups for clients.",
  },
  {
    name: "Jessica Taylor",
    role: "Fashion Designer at LuxeWear",
    image: "https://randomuser.me/api/portraits/women/2.jpg",
    text: "ClothChanger.AI is my secret weapon. I use it to test out new patterns and designs before actually creating physical prototypes. It's saved me countless hours and resources.",
  },
  {
    name: "Andrew Brown",
    role: "E-commerce Store Owner",
    image: "https://randomuser.me/api/portraits/men/62.jpg",
    text: "I’ve integrated ClothChanger.AI into my store’s product pages, allowing customers to visualize how different clothes will look on them. It's improved our conversion rates significantly!",
  },
  {
    name: "Olivia White",
    role: "Content Creator at OutfitInspo",
    image: "https://randomuser.me/api/portraits/women/19.jpg",
    text: "The ability to change outfits with AI has added so much versatility to my content. I can create multiple looks from just one photoshoot!",
  },
];

const TestimonialsSection = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white dark:bg-gray-900 text-gray-700 dark:text-gray-300">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        {/* Section Title */}
        <div className="mb-10 text-center">
          <h2 className="text-4xl font-extrabold text-gray-900 dark:text-white">
            What Our Users Say
          </h2>
          <p className="text-lg text-gray-500 dark:text-gray-400 mt-4">
            Hear from fashion experts, content creators, and store owners about how ClothChanger.AI is revolutionizing their style.
          </p>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="relative bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg hover:shadow-2xl transform hover:-translate-y-3 hover:scale-105 transition-all duration-500 group"
            >
              {/* Decorative Border */}
              <div className="absolute inset-0 z-[-1] bg-gradient-to-r from-purple-500 to-violet-600 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>

              {/* Testimonial Header */}
              <div className="flex items-center space-x-4 mb-6">
                <img
                  className="w-16 h-16 rounded-full border-2 border-violet-500 shadow-md"
                  src={testimonial.image}
                  alt={`${testimonial.name} avatar`}
                />
                <div>
                  <h6 className="text-lg font-bold text-gray-900 dark:text-white">{testimonial.name}</h6>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{testimonial.role}</p>
                </div>
              </div>

              {/* Testimonial Content */}
              <p className="text-gray-600 dark:text-gray-300 text-base leading-relaxed">
                {testimonial.text}
              </p>

              {/* Decorative Quote Icon */}
              <div className="absolute top-4 right-4 text-purple-500 opacity-50 text-5xl group-hover:opacity-100 group-hover:scale-125 transition-all duration-500">
                “
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
