import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="max-w-7xl justify-center item-center mx-auto py-12 px-4 sm:px-6 lg:px-8">
        {/* Upper Section */}
        <div className="flex  md:pl-20 flex-wrap justify-between">
          {/* Logo and Description */}
          <div className="w-full md:w-1/3 mb-8 md:mb-0">
          <Link to="/" className="flex items-center space-x-3 mb-4">
              <img src="/images/logo.png" className="h-12" alt="Clothchanger.ai Logo" />
              <span className="text-2xl font-extrabold text-white">
                ClothChanger.AI
              </span>
            </Link>
            <p className="text-gray-400 text-base leading-relaxed">
              ClothChanger.AI is revolutionizing the way you style yourself.
              Experience AI-driven outfit changes, color modifications, and virtual try-ons, 
              all from the comfort of your device. Discover endless fashion possibilities with just one click!
            </p>
          </div>

          {/* Links */}
          <div className="w-full pl-8 md:w-2/3 flex flex-wrap">
            {/* Company Links */}
            <div className="w-1/2 md:w-1/4 mb-8 md:mb-0">
            <h2 className="text-xl font-semibold text-white mb-4">
                Company
              </h2>
              <ul className="space-y-2">
                <li>
                  <Link to="/about" className="hover:text-violet-500">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/portfolio"
                    className="hover:text-violet-500"
                  >
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:text-violet-500">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-violet-500">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>

            {/* Solutions Links */}
            <div className="w-1/2 md:w-1/4 mb-8 md:mb-0">
            <h2 className="text-xl font-semibold text-white mb-4">
                Explore Features
              </h2>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:text-violet-500">
                    AI Outfit Generator
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-violet-500">
                    Virtual Try-On
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-violet-500">
                    Color Change Tool
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-violet-500">
                    Pattern Transformation
                  </a>
                </li>
              </ul>
            </div>

            {/* Social Media Links */}
            <div className="w-full md:pl-4 md:w-1/4">
              <h2 className="text-xl font-semibold text-white mb-4">
                Connect With Us
              </h2>
              <div className="flex space-x-4">
                <a
                  href="#"
                  className="text-gray-400 hover:text-violet-500 transition-colors"
                >
                  <FaFacebookF size={20} />
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-violet-500 transition-colors"
                >
                  <FaTwitter size={20} />
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-violet-500 transition-colors"
                >
                  <FaInstagram size={20} />
                </a>
                <a
                  href="#"
                  className="text-gray-400 hover:text-violet-500 transition-colors"
                >
                  <FaLinkedinIn size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 border-t border-gray-800 pt-6 text-center">
          <p className="text-gray-500">
            &copy; {currentYear} ClothChanger.AI. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
