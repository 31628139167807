import React from 'react';
import ContactUs from './Contact/ContactUs';
import FAQSection from './Home/FAQSection';
import ContactDetails from './Contact/ContactDetails';

const Contact = () => {
  return (
    <>
      <ContactDetails />
      <ContactUs />
      <FAQSection />
    </>
  );
};

export default Contact;
