import React from 'react';
import { useForm } from 'react-hook-form';

const MapAndContactForm = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  return (
    <section className="bg-gray-50 pt-16 pb-16 md:pb-40">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          
          {/* Map Section */}
          <div className="relative">
          <div className="inline-block bg-purple-100 text-purple-700 font-semibold py-1 px-3 rounded-full text-sm mb-4">
            Get in Touch
          </div>
          {/* Headline */}
          <h2 className="text-4xl font-bold text-gray-900 mb-6 leading-tight">
          Connect & ignite
          the conversation! 
          </h2>
            <iframe
              title="Office Location"
              className="w-full h-full rounded-lg shadow-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.33750346623!2d-73.97968099999999!3d40.6974881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1726334923261!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>

          {/* Contact Form Section */}
          <div className="bg-white p-10 mt-48 md:mt-0 rounded-lg shadow-xl">
            <div className="inline-block bg-purple-100 text-purple-700 font-semibold py-1 px-3 rounded-full text-sm mb-4">
            Send us a message
          </div>
            <h1 className="text-3xl font-semibold text-gray-900 mb-6">Fill up the form</h1>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm text-gray-600">Email Address*</label>
                  <input
                    type="email"
                    {...register('email', { required: 'Email is required' })}
                    placeholder="Enter your email"
                    className="w-full px-4 py-3 border rounded-lg focus:ring-violet-500 focus:border-violet-500"
                  />
                  {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Phone Number</label>
                  <input
                    type="text"
                    {...register('phone')}
                    placeholder="Enter your phone number"
                    className="w-full px-4 py-3 border rounded-lg focus:ring-violet-500 focus:border-violet-500"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm text-gray-600">Your Message</label>
                <textarea
                  {...register('message')}
                  placeholder="Write your message"
                  className="w-full h-24 px-4 py-3 border rounded-lg focus:ring-violet-500 focus:border-violet-500"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-violet-600 text-white py-3 rounded-lg hover:bg-violet-700 focus:ring-4 focus:ring-violet-400"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapAndContactForm;
