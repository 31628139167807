import React from 'react';

const steps = [
  {
    step: 'Step 1',
    title: 'Upload Your Photo',
    description: 'Select an in-focus photo of yourself to apply virtual AI outfits. Make sure the photo is clear for the best results.',
    icon: '📸', // Emoji for icons, you can replace this with an image or icon from react-icons
  },
  {
    step: 'Step 2',
    title: 'Choose an AI Outfit',
    description: 'Explore a variety of AI-generated outfits and select the one you like, or create your own with custom options.',
    icon: '👗', // Emoji for outfit
  },
  {
    step: 'Step 3',
    title: 'Apply & Download',
    description: 'Click on "Generate" to apply the virtual outfit to your photo. Download the generated image directly to your device.',
    icon: '💾', // Emoji for download
  },
];

const EnhancedStepsSection = () => {
  return (
    <section className="p-8 md:p-16 bg-gradient-to-r from-gray-50  to-gray-50">
      <div className="max-w-7xl mx-auto text-center">
        {/* Heading */}
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-gray-900 mb-10 tracking-tight">
          How to Use ClothChanger.AI
        </h2>

        {/* Steps Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {steps.map((step, index) => (
            <div 
            key={index} 
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 transform hover:scale-105"
          >
            {/* Step Icon */}
            <div 
              className="relative w-16 h-16 bg-gradient-to-br from-purple-300 to-indigo-300 text-white rounded-full flex items-center justify-center mx-auto shadow-lg mb-6">
              <div className="text-3xl">
                {step.icon}
              </div>
            </div>

            {/* Geometric Divider (modern touch) */}
            <div className="relative w-full h-1 bg-gradient-to-r from-purple-300 to-indigo-300 rounded-lg mb-4"></div>

            {/* Step Number in Ribbon Style */}
            <div 
              className="absolute top-0 left-0 transform -translate-y-3 -translate-x-3 bg-purple-500 text-white px-3 py-1 rounded-lg shadow-md font-semibold">
              {step.step}
            </div>

            {/* Step Title */}
            <h3 className="text-xl font-bold text-gray-800 mb-4">{step.title}</h3>

            {/* Step Description */}
            <p className="text-base text-gray-600 leading-relaxed mb-4">
              {step.description}
            </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EnhancedStepsSection;
