import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {jwtDecode} from 'jwt-decode';  
import { useAuth } from '../../../Common/AuthProvider';

const SigninPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { login, isAuthenticated } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const characterLimits = {
        email: 100,
        password: 20,
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1);  // Redirect to the previous page if already logged in
        }
    }, [isAuthenticated]);

    // Validation Logic
    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required.';
        } else if (email.length > characterLimits.email) {
            errors.email = `Email must be less than ${characterLimits.email} characters.`;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length > characterLimits.password) {
            errors.password = `Password must be less than ${characterLimits.password} characters.`;
        }
        return errors;
    };

    // Handle the form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const storedUsers = JSON.parse(localStorage.getItem('users')) || [];
            const user = storedUsers.find(u => u.email === email && u.password === password);

            if (user) {
                const token = Math.random().toString(36).substr(2);  // Generate random token
                localStorage.setItem('authToken', token);
                localStorage.setItem('currentUser', JSON.stringify(user));
                login(token);  // Call login function from AuthProvider
                navigate('/model-box'); // Redirect to homepage after successful login
            } else {
                setErrors({ general: 'Invalid email or password.' });
            }
        }
    };

    const handleGoogleSuccess = (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);
        
        const googleUser = {
            email: decoded.email,
            full_name: decoded.name,
        };

        const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
        if (!existingUsers.find(u => u.email === googleUser.email)) {
            existingUsers.push({ email: googleUser.email, password: '', full_name: googleUser.full_name });
            localStorage.setItem('users', JSON.stringify(existingUsers));
        }

        const authToken = Math.random().toString(36).substr(2);  // Generate random token
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('currentUser', JSON.stringify(googleUser));
        login(authToken);  // Call login function from AuthProvider
        navigate('/model-box');  // Redirect to homepage
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-violet-50 to-violet-100 px-4 md:px-0 py-12 md:py-20">
            <div className="max-w-lg mx-auto w-full bg-white p-8 rounded-2xl shadow-lg relative">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-extrabold text-gray-900">Log in to Clothchanger.AI</h2>
                    <p className="text-gray-600">
                        Or <Link to="/signup" className="font-medium text-violet-500 hover:text-violet-600">create a new account</Link>
                    </p>
                </div>

                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div className="relative">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`block w-full px-5 py-3 mt-2 text-sm placeholder-gray-500 rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-violet-500 ${errors.email ? 'border-red-500' : 'border-gray-200'}`}
                            maxLength={characterLimits.email}
                        />
                        {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                    </div>

                    <div className="relative">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <div className="relative">
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`appearance-none block w-full px-5 py-3 mt-2 rounded-lg font-medium bg-gray-100 ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:ring-2 focus:ring-violet-500`}
                                maxLength={100}
                            />
                            <span
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-violet-500 focus:ring-violet-500 border-gray-300 rounded" />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">Remember me</label>
                        </div> 
                    </div>

                    <button
                        type="submit"
                        className="w-full flex font-semibold justify-center py-3 px-4 border border-transparent rounded-full text-white bg-gradient-to-r from-violet-600 to-violet-500 hover:from-violet-700 hover:to-violet-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                    >
                        Log in
                    </button>
                </form>

                <div className="text-center mt-6">
                    <p className="font-bold text-gray-500">OR</p>
                </div>

                <div className="w-full flex justify-center mt-6">
                    <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onFailure={handleGoogleFailure}
                        />
                    </GoogleOAuthProvider>
                </div>

                {errors.general && (
                    <div className="text-red-500 mt-2 text-sm">
                        {errors.general}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SigninPage;
