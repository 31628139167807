import React, { useEffect } from 'react';
import "../../style/modal.css"

const Modelbox = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://gradio.s3-us-west-2.amazonaws.com/4.40.0/gradio.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 md:px-8">
          <div className="py-16 md:py-24">
            <div className="max-w-3xl mx-auto text-center">
              {/* Heading */}
              <h2 className="text-4xl font-extrabold text-violet-700">
                Experience AI-Powered Cloth Change
              </h2>
              <p className="mt-4 text-lg text-gray-600">
                Upload your image and let AI transform your outfit instantly. Experiment with different looks and styles in just a few clicks.
              </p>
              <p className="mt-4 text-sm text-red-300">  
                <span className="font-semibold">Note:</span> The model may take a few seconds to load. Please be patient.
              </p>
            </div>
            <div className="relative w-full z-10 mt-12">
              <gradio-app
                src="https://rol-box-teset-tu2.hf.space"
              ></gradio-app>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modelbox;
