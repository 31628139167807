import React from 'react';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from 'react-feather'; // Using Feather icons for navigation
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../style/carousel.css'; // Optional custom styles

const images = [
  './images/comp-img/3.jpg',
  './images/comp-img/12.jpg',
  './images/comp-img/9.jpg',
  './images/comp-img/2.jpg',
  './images/comp-img/8.jpg',
];

// Custom Next Arrow
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="absolute top-1/2 md:-right-12 right-2 transform -translate-y-1/2 z-10">
      <button
        onClick={onClick}
        className="bg-gray-800 hover:bg-gray-700 text-white  rounded-full shadow-lg transition-all"
      >
        <ChevronRight size={36} />
      </button>
    </div>
  );
};

// Custom Prev Arrow
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="absolute top-1/2 md:-left-12 left-2 transform -translate-y-1/2 z-10">
      <button
        onClick={onClick}
        className="bg-gray-800 hover:bg-gray-700 text-white  rounded-full shadow-lg transition-all"
      >
        <ChevronLeft size={36} />
      </button>
    </div>
  );
};

const SlickCarousel = () => {
  // Slider settings
  const settings = {
    dots: false, // Hide dots
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 2, // Default number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Auto-slide functionality
    autoplaySpeed: 3000, // Auto-slide every 3 seconds
    nextArrow: <NextArrow />, // Custom Next Arrow
    prevArrow: <PrevArrow />, // Custom Prev Arrow
    responsive: [
      {
        breakpoint: 1024, // Tablet or smaller screens
        settings: {
          slidesToShow: 2, // Show 2 slides on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="py-12">
      <div className="max-w-6xl mx-auto">
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index} className="px-4">
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                className="w-full max-h-96 object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default SlickCarousel;
