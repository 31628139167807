import React from 'react';
import SwiperCarousel from './Carousel';

const HeroSection = () => {
  return (
    <section className="relative bg-gradient-to-b from-violet-50 to-violet-100 py-12 md:py-24">
      {/* Background Blobs for Modern Design */}
      <div className="absolute -left-48 bottom-0 w-32 h-32 bg-gradient-to-r from-yellow-100 to-green-100 rounded-full opacity-50 md:w-80 md:h-80"></div>
      <div className="absolute right-10 top-4 w-32 h-32 bg-gradient-to-r from-purple-100 to-pink-100 rounded-full opacity-50 md:w-28 md:h-28"></div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center">
          {/* Main Heading */}
          <h1 className="text-3xl md:text-6xl font-extrabold md:font-bold text-gray-900">
            Instantly Transform Your Look <br />
          </h1>
          <h1 className="text-3xl md:text-6xl pt-2 pb-4 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-violet-600 to-purple-600">
            Using Generative AI
          </h1>

          {/* Subheading */}
          <p className="text-lg md:text-xl max-w-3xl mx-auto text-gray-600 mt-6">
            Transform your look instantly with our AI outfit changer. Upload a photo and explore endless styles tailored just for you. From virtual try-ons to personalized fashion, style yourself effortlessly and for free!
          </p>

          {/* Call to Action Button */}
          <div className="mt-8">
            <a
              href="#"
              className="inline-block px-8 py-3 bg-violet-500 text-white text-lg font-semibold rounded-full shadow-lg transform transition hover:scale-105 hover:bg-violet-600"
            >
              Get Started
            </a>
          </div>
        </div>

        {/* SwiperCarousel Component */}
        <SwiperCarousel />
      </div>
    </section>
  );
};

export default HeroSection;
