import React from 'react';
// import { ArrowRightIcon } from '@heroicons/react/solid';

const CallToActionSection = () => {
  return (
    <section className="relative py-20 bg-white overflow-hidden">
      {/* Background Gradient */}
      <div className="absolute inset-0">
        <div className="bg-gradient-to-r from-violet-100 via-violet-50 to-violet-100 opacity-90 w-full h-full"></div>
      </div>

      {/* Decorative Blobs */}
      <div className="absolute -bottom-32 -left-32 w-80 h-80 bg-violet-200 rounded-full mix-blend-multiply filter blur-2xl opacity-60 animate-blob"></div>
      <div className="absolute -top-32 -right-32 w-80 h-80 bg-violet-300 rounded-full mix-blend-multiply filter blur-2xl opacity-60 animate-blob animation-delay-2000"></div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-5xl font-extrabold text-violet-700 mb-6">
          Transform Your Style with <span className="text-violet-500">AI Magic</span>
        </h2>
        <p className="text-xl text-gray-700 mb-12">
          Your dream wardrobe is just a few clicks away. Use our AI to experiment with different clothing styles, colors, and patterns in no time.
        </p>

        {/* Buttons */}
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <a
            href="#"
            className="inline-flex items-center justify-center px-8 py-4 bg-violet-500 text-white font-semibold rounded-full shadow-lg transform transition hover:scale-105 hover:bg-violet-600"
          >
            Upload Your Outfit
            {/* <ArrowRightIcon className="w-5 h-5 ml-3" /> */}
          </a>
          <a
            href="#"
            className="inline-flex items-center justify-center px-8 py-4 bg-white text-violet-500 border border-violet-500 font-semibold rounded-full shadow-lg transform transition hover:scale-105 hover:bg-violet-50"
          >
            Try On AI Clothes
            {/* <ArrowRightIcon className="w-5 h-5 ml-3" /> */}
          </a>
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
