import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactDetails = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <div className="inline-block bg-purple-100 text-purple-700 font-semibold px-3 rounded-full text-sm py-1">
          Let’s Talk
          </div>
          <h1 className="text-4xl font-extrabold text-gray-900 mt-4">
            Engage in conversation with skilled engineers.
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Chat with us */}
          <div className="bg-white shadow-lg rounded-lg p-8 text-center transition transform hover:scale-105 hover:shadow-xl">
            <FaEnvelope className="text-violet-600 text-4xl mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-gray-900">Chat with us</h3>
            <p className="text-gray-600 mt-2">Mon - Fri: 9am to 6pm</p>
          </div>

          {/* Call Us */}
          <div className="bg-white shadow-lg rounded-lg p-8 text-center transition transform hover:scale-105 hover:shadow-xl">
            <FaPhoneAlt className="text-violet-600 text-4xl mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-gray-900">Give us a call</h3>
            <p className="text-gray-600 mt-2">+480-555-0103</p>
            <p className="text-gray-600">+239-555-0108</p>
          </div>

          {/* Email Us */}
          <div className="bg-white shadow-lg rounded-lg p-8 text-center transition transform hover:scale-105 hover:shadow-xl">
            <FaMapMarkerAlt className="text-violet-600 text-4xl mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-gray-900">Email with us</h3>
            <p className="text-gray-600 mt-2">support@clothchanger.ai</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetails;
