import React from 'react';
import CallToActionSection from './Home/Calltoactionsection';
import HeroSection from './Home/HomePageHero';
import FAQSection from './Home/FAQSection';
import StepsSection from './Home/StepsSection';
import LayoutComponent from './Home/LayoutComponent';

const Home = () => {
    return (
        <div> 
            <HeroSection />
            <StepsSection />
            <LayoutComponent />
            <FAQSection />
            <CallToActionSection />
        </div>
    );
};

export default Home;
